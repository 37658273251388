<template>
  <b-dropdown id="language-switcher" class="common-dropdown" variant="link" no-caret>
    <template v-slot:button-content>
      <b-img :src="require(`@/assets/img/flags/${$i18n.locale}.svg`)" :alt="$i18n.locale" />
    </template>
    <slot v-for="(locale, i) in locales">
      <b-dropdown-item v-if="locale !== $i18n.locale" :key="`locale${i}`" @click="changeLocale(locale)">
        <b-img :src="require(`@/assets/img/flags/${locale}.svg`)" :alt="locale" />
        {{ $t(`general.locales.${locale}`) }}
      </b-dropdown-item>
    </slot>
  </b-dropdown>
</template>

<style lang="scss" scoped>
@import "./LanguageSwitcher";
</style>

<script>
import { setRequestsHeader } from '@/api';

export default {
  data() {
    return {
      locales: Object.keys(this.$i18n.messages),
    };
  },

  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      setRequestsHeader(locale);
    },
  },
};
</script>
